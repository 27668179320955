<template>
  <form class="dg-form">
    <ValidationObserver ref="validation">
      <ValidationProvider rules="required" v-slot="{ errors }">
        <DgPortalInput
          class="dg-col-12"
          label="SUBJECT"
          v-model="form.subject"
          :error="errors[0]"
        />
      </ValidationProvider>
      <ValidationProvider rules="required" v-slot="{ errors }">
        <DgPortalTextarea
          class="dg-col-12"
          label="MESSAGE"
          v-model="form.message"
          :rows="5"
          :error="errors[0]"
        />
      </ValidationProvider>
    </ValidationObserver>
  </form>
</template>
<script>
import { DgPortalInput, DgPortalTextarea } from "@engenharia/dashgoo-ui";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "FormDashboard",
  components: {
    DgPortalInput,
    DgPortalTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    form: {
      subject: "",
      message: "",
    },
  }),
  methods: {
    onSubmit() {
      this.$toaster.show(this.$t("EMAIL_SENT"), "success");
    },
  },
};
</script>

<style lang="scss" scoped>
.dg-form {
  > span {
    width: 100%;
  }
}

.dg-form {
  @media (min-width: 768px) {
    margin-right: -16px;
    margin-left: -16px;
  }
}
</style>
